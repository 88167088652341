export const ENDPOINTS = {
    login: "/login.php",
    register: "/register.php",
    getProfile: "/get-profile.php",
    uploadFile: "/upload-file.php",
    shareFile: "/share-file.php",
    getFiles: "/get-files.php",
    getRepo: "/get-repo.php",
    deleteFile: "/delete-file.php",
    addAdmin: "/add-admin.php",
    getUsers: "/get-users.php",
    deleteUser: "/delete-user.php",
    generateUsersSheet: "/generate-users-sheet.php"
};