import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../shared/Input";
import { ENDPOINTS } from "../../utils/Endpoints";
import { MakePostRequest } from "../../utils/MakeRequest";
import { getUsers } from "../../utils/getUsers";

function UploadFile() {

    const navigate = useNavigate();
    let [files, setFiles] = useState("");
    let [isChecked, setIsChecked] = useState(false);
    let [recipient, setRecipient] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    let [users, setUsers] = useState(<div className="boxed center"><b>Loading...</b></div>);

    const addFiles = (e) => {
        e.preventDefault();
        const fileInput = document.getElementById("file-input");
        fileInput.click();
    }

    const onPick = (e) => {
        let arr = [];
        const filesDiv = document.getElementsByClassName("files-div");
        // arr.push(file);
        // console.log(file);
        let newFile = document.createElement("div");
        newFile.innerHTML = `<div class='row align-center mt-8'><i class='fa fa-file'></i> <b>${e.target.files[0].name}</b></div>`;
        filesDiv[0].appendChild(newFile);
        setFiles(e.target.files[0]);

    }

    const submit = () => {
        setIsLoading(true);
        if (files === "") {
            setIsLoading(false);
            return alert("Files cannot be empty");
        }
        if (isChecked && recipient === "") {
            setIsLoading(false);
            return alert("Please add a valid recipient's email");
        }
        // return console.log(files);
        let user = JSON.parse(localStorage.getItem("userProfile"));
        let form = new FormData();
        form.append("file", files);
        form.append("email", user.email);

        const url = ENDPOINTS.uploadFile;

        MakePostRequest(form, url, (res, err) => {

            if (err) return alert(err.message);
            if (res.status === true) {
                if (isChecked && recipient !== "") {
                    let raw = new FormData();
                    raw.append("file_path", res.data);
                    raw.append("file_name", res.name);
                    raw.append("repo_id", res.name);
                    raw.append("sender", user.email);
                    raw.append("recipient", JSON.stringify(recipient));

                    const shareUrl = ENDPOINTS.shareFile;
                    MakePostRequest(raw, shareUrl, (result, error) => {
                        setIsLoading(false);
                        if (error) {
                            return alert(error.message);
                        }
                        if (result.status === true) {
                            alert(result.data);
                            navigate(`/?user=${window.btoa(user.email)}`);
                        } else {
                            alert(result.data);
                        }
                    });
                } else {
                    alert(`${res.data} uploaded successfully`);
                    navigate(`/?user=${window.btoa(user.email)}`);
                }
            } else {
                alert(res.data);
                navigate("/");
            }
        });

    }


    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("userProfile"));
        if (userProfile.role !== "ADMIN") {
            return navigate("/");
        }
        getUsers((user, error) => {
            if (error) return alert(error.message);
            if (user.status === true) {
                setUsers(
                    user.data.map((each, i) => {
                        return (
                            <div key={i} className="row align-center mt-8">
                                <div style={{ width: "90%" }}>
                                    <div>{each.first_name} {each.last_name}</div>
                                </div>
                                <div className="flex-1">
                                    <input type="checkbox" value={each.email} onChange={(e) => {
                                        console.log(e.target.value, e.target.checked)
                                        if (e.target.checked === true) {
                                            setRecipient((prev) => {
                                                return [...prev, e.target.value]
                                            });
                                        } else {
                                            setRecipient((prev) => {
                                                return prev.splice(prev.indexOf(e), 1)
                                            });
                                        }
                                    }} />
                                </div>
                            </div>
                        )
                    })
                )
            } else {
                return alert(user.data);
            }
        });
    }, []);

    return (
        <div>
            <div className="boxed center">
                <h2>Upload File</h2>
            </div>
            <div className="mt-16 boxed">
                <div className="">
                    <div className="files-div"></div>
                    <div className="mt-16">
                        {files !== "" &&
                            <div><input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} /> <span>Share with recipient</span></div>
                        }
                        {isChecked &&
                            <div className="mt-8">
                                <Input
                                    placeholder="Search Recipient"
                                    type="text"
                                    
                                />
                                <div style={{height: 200, overflowY: "auto", marginTop: 16, border: "1px solid #c0c0c0", borderRadius: 6, padding: 8}}>
                                    {users}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="mt-16 center">
                        <input type="file" id="file-input" onChange={onPick} hidden />
                        <div>
                            <button className="alt-btn-black" onClick={addFiles} disabled={isLoading}>Add File</button>
                        </div>
                        <div>
                            <button className="primary-btn mt-16" onClick={submit} disabled={isLoading}>{isLoading ? "Uploading..." : "Upload"}</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadFile;