import React from "react";
import logo from "../assets/images/logo.png";

function Logo(props) {
    return (
        <div>
            <a href="https://thegoodpracticecompany.co.uk">
                <img src={logo} width={props.width || 200} height={props.height || "auto"} alt="logo" style={{objectFit: "contain"}}/>
            </a>
        </div>
    )
}

export default Logo;