import { API_URL } from "../shared/Variables";
import { getParams } from "./getParams";

export function MakePostRequest(data, url, callback) {
    let myHeaders = new Headers();
    let params = getParams();
    myHeaders.append("bearer", params.user);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: data,
    }
    console.log(`${API_URL}${url}`);
    console.log(params.user);

    fetch(`${API_URL}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => callback(data, undefined))
        .catch(err => callback(undefined, err))
}

export function MakeGetRequest(url, callback) {
    let myHeaders = new Headers();
    let params = getParams();
    myHeaders.append("bearer", params.user);

    const requestOptions = {
        headers: myHeaders,
        method: "GET"
    }

    fetch(`${API_URL}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => callback(data, undefined))
        .catch(err => callback(undefined, err))
}