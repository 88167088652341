import React from "react";
import Logo from "../shared/Logo";

function DashboardHeader(props) {
    return (
        <div>
            <div className="row align-center">
                <div className="flex-1 boxed">
                    {window.innerWidth < 992 && <Logo width={120}/>}
                </div>
                <div className="flex-1 flex-end boxed">
                    {window.innerWidth < 992 &&
                        <div>
                            <i className="fa fa-bars" style={{fontSize: "2em"}} onClick={() => {
                                window.innerWidth < 992 && (() => {
                                    let sideNav = document.getElementsByClassName("dashboard-sidenav-div")[0];
                                    sideNav.style.width = "100%";
                                })();
                            }}></i>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DashboardHeader;