import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ENDPOINTS } from "../../utils/Endpoints";
import { getProfile } from "../../utils/getProfile";
import { getUsers } from "../../utils/getUsers";
import { MakeGetRequest, MakePostRequest } from "../../utils/MakeRequest";


function Home() {

    let [repo, setRepo] = useState(<div className="center"><b>Loading...</b></div>);
    let [userType, setUserType] = useState("");
    let [showPopup, setShowPopup] = useState(false);
    let [isSharing, setIsSharing] = useState(false);
    let [recipient, setRecipient] = useState([]);
    let [users, setUsers] = useState(<div className="center"><b>Loading...</b></div>);
    let [file, setFile] = useState("");
    let [sender, setSender] = useState("");
    const navigate = useNavigate();


    const shareFile = () => {
        setIsSharing(true);
        if (recipient.length === 0) {
            setIsSharing(false);
            return alert("Please select one or more recipients");
        } else {
            const shareUrl = ENDPOINTS.shareFile;

            let fileToShare = new FormData();
            fileToShare.append("file_path", file.path);
            fileToShare.append("file_name", file.name);
            fileToShare.append("sender", sender);
            fileToShare.append("recipient", JSON.stringify(recipient));

            MakePostRequest(fileToShare, shareUrl, (res, err) => {
                setIsSharing(false);
                if (err) return alert(err.message);
                if (res.status === true) {
                    alert(res.data);
                    setShowPopup(false);
                    navigate(`/?user=${window.btoa(sender)}`);
                } else {
                    alert(res.data);
                }
            });
        }
    }

    useEffect(() => {
        getProfile((res, err) => {
            if (err) return alert(err.message);
            if (res.status === true) {
                const { role } = res.data[0];
                console.log(res.data[0]);
                console.log("role", role);
                sessionStorage.setItem("bearer", window.btoa(res.data[0].email));
                setUserType(role);
                (role === "USER") && (() => {
                    let url = ENDPOINTS.getFiles;
                    MakeGetRequest(url, (res, err) => {
                        if (err) return console.log(err.message);
                        if (res.status === true) {
                            const { data } = res;
                            if (data.length > 0) {
                                setRepo(
                                    data.map((each, i) => {
                                        return (
                                            <div key={i} className="row align-center mt-8">
                                                <div style={{ width: "80%" }}>
                                                    <div className="row align-center">
                                                        <div style={{ paddingRight: 8 }}><i className="fa fa-file"></i></div>
                                                        <div style={{ paddingLeft: 8 }}>{each.file_name}</div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 flex-end">
                                                    <a href={`${process.env.PUBLIC_URL}/uploads/${each.file_name}`} download><i className="fa fa-download download-icon" style={{ fontSize: window.innerWidth > 992 ? "1.5em" : "1em" }}></i></a>
                                                </div>
                                            </div>
                                        )
                                    })
                                );
                            } else {
                                setRepo(
                                    <>
                                        <div className="center"><b>No files have been shared with you yet...</b></div>
                                        <div className="center mt-16"><b>Files shared with you will show up here</b></div>
                                    </>
                                )
                            }
                            console.log(res);
                        } else {
                            return alert(res.data);
                        }
                    });
                })();

                role === "ADMIN" && (() => {
                    let url = ENDPOINTS.getRepo;
                    MakeGetRequest(url, (res, err) => {
                        if (err) return console.log(err.message);
                        if (res.status === true) {
                            const { data } = res;
                            if (data.length > 0) {
                                setRepo(
                                    <div style={{ marginTop: "15%" }}>
                                        {
                                            data.map((each, i) => {
                                                return (
                                                    <div key={i} className="row align-center mt-16">
                                                        <div style={{ width: "80%" }}>
                                                            <div className="row align-center">
                                                                <div style={{ paddingRight: 8 }}><i className="fa fa-file"></i></div>
                                                                <div style={{ paddingLeft: 8 }}>{each.name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 flex-end">
                                                            <i className="fa fa-share-alt" style={{ fontSize: window.innerWidth > 992 ? "1.5em" : "1em", marginRight: 8, cursor: "pointer" }} onClick={() => {
                                                                const userProfile = JSON.parse(localStorage.getItem("userProfile"));
                                                                if (!userProfile || userProfile === null || userProfile === "") {
                                                                    return alert("Unable to get user details");
                                                                }
                                                                setShowPopup(true);
                                                                setFile(each);
                                                                setSender(userProfile.email);
                                                            }}></i>
                                                            <i className="fa fa-trash trash-icon" style={{ fontSize: window.innerWidth > 992 ? "1.5em" : "1em", color: "red", marginLeft: 16, cursor: "pointer" }} onClick={() => {
                                                                const trashIcon = document.getElementsByClassName("trash-icon")[i];
                                                                trashIcon.className = "fa fa-spinner spin";
                                                                let fileData = new FormData();
                                                                fileData.append("file_id", each.id);
                                                                fileData.append("file_path", each.path);
                                                                const deleteUrl = ENDPOINTS.deleteFile;
                                                                MakePostRequest(fileData, deleteUrl, (res, err) => {
                                                                    if (err) return alert(err.message);
                                                                    if (res.status === true) {
                                                                        alert(res.data);
                                                                        trashIcon.className = "fa fa-trash trash-icon";
                                                                        window.location.reload();
                                                                    } else {
                                                                        alert(res.data);
                                                                        trashIcon.className = "fa fa-trash trash-icon";
                                                                    }
                                                                });
                                                            }}></i>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                );
                                getUsers((user, error) => {
                                    if (error) return alert(error.message);
                                    if (user.status === true) {
                                        setUsers(
                                            user.data.map((each, i) => {
                                                return (
                                                    <div key={i} className="row align-center mt-8">
                                                        <div style={{ width: "90%" }}>
                                                            <div>{each.first_name} {each.last_name}</div>
                                                        </div>
                                                        <div className="flex-1">
                                                            <input type="checkbox" value={each.email} onChange={(e) => {
                                                                console.log(e.target.value, e.target.checked)
                                                                if (e.target.checked === true) {
                                                                    setRecipient((prev) => {
                                                                        return [...prev, e.target.value]
                                                                    });
                                                                } else {
                                                                    setRecipient((prev) => {
                                                                        return prev.splice(prev.indexOf(e), 1)
                                                                    });
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    } else {
                                        return alert(user.data);
                                    }
                                });
                            } else {
                                setRepo(
                                    <>
                                        <div className="center"><b>No files have been uploaded yet...</b></div>
                                        <div className="center mt-16"><b>Uploaded files will show up here</b></div>
                                    </>
                                )
                            }
                        } else {
                            return alert(res.data);
                        }
                    });
                })();

            } else {
                alert(res.data);
            }
        });
    }, []);

    return (
        <div className="dashboard-page">
            <div className="boxed">
                <div className="row align-center">
                    <div className="flex-1">
                        <h2>Repository</h2>
                    </div>
                    {
                        userType === "ADMIN" &&
                        <div className="flex-1 flex-end">
                            <Link to={`/upload-file?user=${sessionStorage.getItem('bearer')}`}><button className="primary-btn">Upload File</button></Link>
                        </div>
                    }
                </div>

                <div className="mt-16">
                    {repo}
                </div>
                <div className="mt-16 center">
                    <div>
                        <button className="primary-btn" onClick={() => {
                            window.location.href = "https://www.thegoodpracticecompany.co.uk/library/";

                        }}>Explore Library</button>
                    </div>
                </div>
            </div>
            {
                showPopup &&
                <div style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    bottom: 0,
                    background: "rgba(0, 0, 0, 0.5)",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center"
                }}>

                    <div className="elevated-div" style={{ width: window.innerWidth < 992 ? "100%" : "85%", position: "absolute", bottom: 0, right: 0, height: "90%", }}>
                        <div style={{ position: "relative", height: "100%" }}>
                            <div className="boxed" style={{ height: "20%", overflow: "hidden" }}>
                                <h2 className="center fw-700">Share File</h2>
                                <div className="mt-8">
                                    <input placeholder="Search User" className="form-input" type="email" />
                                </div>
                            </div>
                            <div className="boxed" style={{ height: "60%", overflowY: "auto" }}>
                                {users}
                            </div>
                            <div className="boxed" style={{ height: "20%", overflow: "hidden" }}>
                                <div className="mt-8">
                                    <button className="form-btn" disabled={isSharing} onClick={shareFile}>{isSharing ? "Sending..." : "Share"}</button>
                                </div>
                                <div className="mt-8">
                                    <button className="form-btn-red" onClick={() => {
                                        setRecipient([]);
                                        setShowPopup(false);
                                    }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Home;