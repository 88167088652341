import { API_URL } from "../shared/Variables";
import { ENDPOINTS } from "./Endpoints";
import { getParams } from "./getParams";

export function getUsers(callback) {
    const params = getParams();
    if (!params || !params.hasOwnProperty("user")) {
        return callback(undefined, {message: "Unauthorized"});
    }
    const url = `${API_URL}${ENDPOINTS.getUsers}`;
    const myHeaders = new Headers();
    myHeaders.append("bearer", localStorage.getItem("bearer") !== null ? localStorage.getItem("bearer") : params.user);

    const requestOptions = {
        headers: myHeaders
    };

    fetch(url, requestOptions)
    .then(response => response.json())
    .then(data => callback(data, undefined))
    .catch(err => callback(undefined, err));
}