import React from "react";
import { Link } from "react-router-dom";

function ServiceFormat(props) {
    return (
        <Link to={`/service-page?service=${props.title}`}>
        <div style={{ border: "1px solid #313639" }}>
            <div>
                <img src={props.imgUrl} alt="img" width={"100%"} height={200} style={{ objectFit: "cover" }} />
            </div>
            <div className="boxed">
                <h2>{props.title}</h2>
            </div>
            <div className="boxed">{props.intro}</div>
            <div className="boxed">
                    <b><i>Read More</i></b>
            </div>
        </div>
        </Link>
    )
}

export default ServiceFormat;