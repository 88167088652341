import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { BASE_URL } from "./Variables";
import { getProfile } from "../utils/getProfile";

function SideNav() {
    let [links, setLinks] = useState([]);
    const navigate = useNavigate();

    const closeSideNav = () => {
        let sideNav = document.getElementsByClassName("dashboard-sidenav-div")[0];
        sideNav.style.width = 0;
    }

    useEffect(() => {
        getProfile((res, err) => {
            if (err) return console.log(err.message);
            if (res.status === true) {
                localStorage.setItem("userProfile", JSON.stringify(res.data[0]));
                sessionStorage.setItem("bearer", res.data[0].email)
                setLinks(
                    res.data[0].role === "USER" ?
                        [
                            { name: "Repository", route: `/?user=${window.btoa(res.data[0].email)}` },
                            { name: "Policy Library", route: `/?user=${window.btoa(res.data[0].email)}` },
                            { name: "Book Consultation", route: `/?user=${window.btoa(res.data[0].email)}` },
                            { name: "Contact Support", route: `/?user=${window.btoa(res.data[0].email)}` },
                        ] :
                        [
                            { name: "Repository", route: `/?user=${window.btoa(res.data[0].email)}` },
                            { name: "Users", route: `/users?user=${window.btoa(res.data[0].email)}` },
                            { name: "Add Admin", route: "/add-admin" },
                        ]
                )
            } else {
                console.log(res);
            }
        });
    }, []);

    return (
        <div>
            <div className="boxed" style={{ position: "relative", height: "100vh" }}>
                {window.innerWidth < 992 &&
                    <div style={{
                        position: "absolute",
                        top: "5%",
                        right: "5%"
                    }}>
                        <i className="fa fa-times"
                            style={{
                                color: "red",
                                fontSize: "2em"
                            }}
                            onClick={() => {
                                window.innerWidth < 992 && (() => {
                                   closeSideNav()
                                })();
                            }}></i>
                    </div>
                }
                <div style={{
                    marginTop: window.innerWidth < 992 ? "15%" : 0,
                }}>
                    <div className="center" style={{ marginBottom: "25%" }}>
                        <Logo width={100} />
                    </div>
                    {
                        links.map((each, i) => {
                            return (
                                <Link to={each.route} key={i} onClick={(e) => {
                                    if (each.name.toLowerCase().endsWith("library")) {
                                        e.preventDefault();
                                        window.location.href = "https://www.thegoodpracticecompany.co.uk/library/";
                                        return;
                                    }
                                    if (each.name.toLowerCase().endsWith("support")) {
                                        e.preventDefault();
                                        const a = document.createElement("a");
                                        a.setAttribute("target", "_blank");
                                        a.href = "https://wa.me/447903094884";
                                        return a.click();
                                    }
                                    window.innerWidth < 992 && closeSideNav()
                                    }}>
                                    <div className="mt-8">
                                        {each.name}
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
                <div style={{
                    position: "absolute",
                    bottom: "5%",

                }}>
                    <button className="primary-btn-red" onClick={() => {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.href = BASE_URL;
                    }}>Log Out</button>
                </div>
            </div>
        </div>
    )
}

export default SideNav;