import React, {useState} from "react";
import { ENDPOINTS } from "../../utils/Endpoints";
import { MakePostRequest } from "../../utils/MakeRequest";

function AddAdmin() {

    let [email, setEmail] = useState("");
    let [isLoading, setIsLoading] = useState(false);

    const addAdmin = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (email === "" || email.length === 0 || !email.includes("@") || !email.includes(".")) {
            setIsLoading(false);
            return alert("Please enter a valid email address");
        } else {
            let raw = new FormData();
            raw.append("email", email);
            const url = ENDPOINTS.addAdmin;

            MakePostRequest(raw, url, (res, err) => {
                if (err) {
                    setIsLoading(false);
                    return alert(err.message);
                } else {
                    setIsLoading(false);
                    if (res.status === true) {
                        alert(res.data); 
                    } else {
                        alert(res.data);
                    }
                }
            })
        }
    }

    return (
        <div>
            <div className="flex">
                <div className="flex-1 boxed">
                    <div><b>Enter the email of the user to make admin</b></div>
                    <div className="mt-16">
                        <input className="form-input" type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="mt-16 center">
                        <button className="primary-btn" onClick={addAdmin} disabled={isLoading}>{isLoading ? "Loading..." : "Add Admin"}</button>
                    </div>
                </div>
                <div className="flex-1"></div>

            </div>
        </div>
    )
}

export default AddAdmin;