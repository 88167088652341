import React from "react";

function Test() {
    return (
        <div className="boxed">
            <h2>Testing!!!</h2>
            <h2>Testing!!!</h2>
            <h2>Testing!!!</h2>
            <h2>Testing!!!</h2>
            <h2>Testing!!!</h2>
            <h2>Testing!!!</h2>
        </div>
    )
}

export default Test;