import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS } from "../../utils/Endpoints";
import { getParams } from "../../utils/getParams";
import { getUsers } from "../../utils/getUsers";
import { MakeGetRequest, MakePostRequest } from "../../utils/MakeRequest";

function Users() {

    const navigate = useNavigate();
    let [users, setUsers] = useState(<div className="boxed center"><b>Loading...</b></div>);
    const deleteUser = (user, i) => {
        let icon = document.getElementsByClassName("trash-icon")[i];
        const confirm = window.confirm(`Are you sure you want to delete ${user.email} ?`);
        if (confirm === true) {
            icon.className = "fa fa-spinner spin";
            const url = ENDPOINTS.deleteUser;
            let raw = new FormData();
            raw.append("id", user.id);
            MakePostRequest(raw, url, (res, err) => {
                if (err) {
                    icon.className = "fa fa-trash trash-icon";
                    alert(err.message);
                }
                if (res.status === true) {
                    alert(res.data);
                    window.location.reload();
                } else {
                    icon.className = "fa fa-trash trash-icon";
                    alert(res.data);
                }
                console.log(res);
            });
        }
    }

    const exportUsers = () => {
        const exportBtn = document.getElementsByClassName("export-btn")[0];
        exportBtn.disabled = true;
        exportBtn.innerHTML = "<i class='fa fa-spinner spin'></i>";

        const url = ENDPOINTS.generateUsersSheet;
        const params = getParams();

        let raw = new FormData();
        raw.append("bearer", params.user);
        MakePostRequest(raw, url, (res, err) => {
            if (err) {
                alert(err.message);
                exportBtn.disabled = false;
                exportBtn.innerHTML = "<div>Export</div>";
            };
            if (res.status === true) {
                const d = new Date();
                let anchor = document.createElement("a");
                anchor.setAttribute("target", "_blank");
                anchor.setAttribute("download", `users${d.getFullYear()}_${d.getMonth()}_${d.getDate()}.csv`);
                anchor.href = `${process.env.PUBLIC_URL}/uploads/users.csv`;
                anchor.click();
                exportBtn.disabled = false;
                exportBtn.innerHTML = "<div>Export</div>";

            } else {
                alert(res.data);
                exportBtn.disabled = false;
                exportBtn.innerHTML = "<div>Export</div>";
            }
        });

    }

    useEffect(() => {
        const _user = localStorage.getItem("userProfile");
        const user = JSON.parse(_user);
        if (user.role !== "ADMIN") {
            return navigate("/");
        } else {
            getUsers((res, err) => {
                if (err) return alert(err.message);
                if (res.status === true) {
                    setUsers(
                        <table>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email Address</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                res.data.map((each, i) => {
                                    return (
                                            <tr key={i}>
                                                <td>{each.first_name}</td>
                                                <td>{each.last_name}</td>
                                                <td>{each.email}</td>
                                                <td><i className="fa fa-trash trash-icon" style={{fontSize: "1.5em", color: "red"}} onClick={() => deleteUser(each, i)}></i></td>
                                            </tr>
                                    );
                                })
                            }
                             </tbody>
                        </table>
                    )

                }
                console.log(res);
            })
        }
    }, []);

    return (
        <div>
            <div className=" row align-center">
                <div className="flex-1 boxed">
                    <h2>Users</h2>
                </div>
                <div className="flex-1 flex-end boxed">
                    <button className="primary-btn export-btn" onClick={exportUsers}>Export</button>
                </div>
            </div>
            <div className="boxed" style={{overflowX: 'auto'}}>
                {users}
            </div>
        </div>
    )
}
export default Users;