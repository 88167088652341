import React from "react";
import work from "../../assets/images/work.jpeg";
import Input from "../../shared/Input";

function Contact() {
    return (
        <div>
            <div className="boxed">
                <div className="flex">
                    <div className="flex-1 boxed">
                        <div className="contact-title">Let's work together</div>
                        <div className="mt-16">
                            <img src={work} alt="img" width={"100%"} height={"auto"} style={{ objectFit: "contain" }} />
                        </div>
                    </div>
                    <div className="flex-1 boxed">
                        <div className="mt-16">
                            <div className="contact-title">Mailing Address</div>
                            <div className="mt-8">
                                <i className="fa fa-map-marker"></i>  <span>30 Churchill Place, Canary Wharf, E14 5RE</span>
                            </div>
                        </div>
                        <div className="mt-16">
                            <div className="contact-title">Phone Number</div>
                            <div className="mt-8">
                                <i className="fa fa-phone"></i>  <span>+442074594519</span>
                            </div>
                        </div>
                        <div className="mt-16">
                            <div className="contact-title">Email Address</div>
                            <div className="mt-8">
                                <i className="fa fa-envelope"></i>  <span>hello@thegoodpracticecompany.co.uk</span>
                            </div>
                        </div>
                        <div className="mt-16">
                            <div className="contact-title">Socials</div>
                            <div className="mt-8">
                                <i className="fa fa-instagram"></i>  <span>TheGoodPracticeCompany</span>
                            </div>
                        </div>
                        <div className="mt-16">
                            <div className="contact-title">Send Us a Message</div>
                            <div>
                                <div className="mt-16">
                                    <input
                                        placeholder="Name"
                                        type="text"
                                        className="form-input"
                                    />
                                </div>
                                <div className="mt-16">
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        className="form-input"
                                    />
                                </div>
                                <div className="mt-16">
                                    <textarea className="form-input" placeholder="Your Message" rows="6"></textarea>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;