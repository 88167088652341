import React from "react";

function Input(props) {
    return (
        <div>
            <input
                className="form-input"
                type={props.type}
                placeholder={props.placeholder}
                onChange={(e) => props.onChange && props.onChange(e)}
            />
        </div>
    )
}

export default Input;