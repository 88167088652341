import React from "react";
import { Routes, Route } from "react-router-dom";
import DashboardHeader from "../../shared/DashboardHeader";
import SideNav from "../../shared/SideNav";
import Home from "./Home";
import { ENDPOINTS } from "../../utils/Endpoints";
import { MakeGetRequest } from "../../utils/MakeRequest";
import UploadFile from "./UploadFile";
import Test from "./Test";
import AddAdmin from "./AddAdmin";
import Users from "./Users";

function Index() {

    return (
        <div className="dashboard">
            <div className="dashboard-sidenav-div">
                <SideNav />
            </div>
            <div className="dashboard-body-div">
                <DashboardHeader/>
                <Routes>
                    <Route exact path={"/"} element={<Home />} />
                    <Route exact path={"/upload-file"} element={<UploadFile />} />
                    <Route exact path={"/add-admin"} element={<AddAdmin />} />
                    <Route exact path={"/users"} element={<Users />} />
                    <Route exact path={"/test"} element={<Test />} />
                </Routes>
            </div>
        </div>
    )
}

export default Index;