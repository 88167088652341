import React from "react";
import { Routes, Route } from "react-router-dom";
import Client from "./pages/client/index";
import Admin from "./pages/admin/Index";

function App() {
	return (
		<>
			<Routes>
				{/* <Route exact path={"/*"} element={<Client/>}/> */}
				<Route exact path={"/*"} element={<Admin/>}/>
			</Routes>
		</>
	)
}

export default App;